
import {defineComponent} from 'vue';
import ListLayout from '@/layouts/ListLayout.vue';
import useTokenList from '@/views/token/list/tokenList';

export default defineComponent({
  name: 'TokenList',
  props: {
    noActions: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    ListLayout,
  },
  setup() {
    return {
      ...useTokenList(),
    };
  },
});
